/* eslint-disable react-hooks/rules-of-hooks */

import firebase from "firebase/compat";
import { useTranslation } from "react-i18next";
import useSettings from "hooks/useSettings";
import {
  Bed,
  Home,
  Elevator,
  AcUnit,
  ViewWeek,
  Security,
  WbSunny,
  LocalParking,
  Accessible,
  FormatPaint,
  DoorBack,
  Balcony
} from "@material-ui/icons";
import { fNumber } from "../formatNumber";
import { fDate } from "utils/formatTime";
import type { CSSProperties } from "react";
import type { SvgIconComponent } from "@material-ui/icons";
import type { Listing } from "../../@types/listings/listing";

export function getFullAddress({
  address,
  neighborhood,
  city
}: Pick<Listing, "address" | "city" | "neighborhood">): string {
  return [address, neighborhood, city].filter(Boolean).join(", ");
}

export function clampLineStyle(lineClamp: number): CSSProperties {
  return {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: lineClamp,
    WebkitBoxOrient: "vertical"
  };
}

export type PropertyDetailsProps = {
  listing: Listing;
  withOptionalProps?: boolean;
};

export type Details = {
  title: string;
  value: string | number;
  Icon?: SvgIconComponent;
  usingRTL?: boolean;
};

export function createPropertyDetails({
  listing,
  withOptionalProps
}: PropertyDetailsProps): Details[] {
  const {
    fee,
    rooms,
    floor,
    hasBars,
    hasGarden,
    floorOutOf,
    hasParking,
    hasStorage,
    hasTerrace,
    cleaningFee,
    exclusivity,
    listingType,
    housingUnit,
    hasElevator,
    projectName,
    listingState,
    evictionDate,
    sizeOfGardenM2,
    protectedSpace,
    sizeOfTerraceM2,
    feeIncludesTaxes,
    sizeOfPropertyM2,
    municipalityTaxes,
    hasAirConditioning,
    addressAreaNickname,
    accessibleForDisabled
  } = listing;

  const { t: translate } = useTranslation();

  return [
    {
      title: translate("listingsPage.tableColumns.rooms"),
      value: `${rooms ?? "-"}`,
      Icon: Bed
    },
    {
      title: translate("listingsPage.tableColumns.floor"),
      value: floor
        ? floorOutOf
          ? `${floor} ${translate("listingsPage.outOf")} ${floorOutOf}`
          : floor
        : "-",
      Icon: Home,
      usingRTL: true
    },
    {
      title: translate("listingsPage.tableColumns.sizeOfPropertyM2"),
      value: `${sizeOfPropertyM2 ?? "-"} m²`
    },
    {
      title: translate("listingsPage.tableColumns.hasElevator"),
      value: boolToYesNo(hasElevator),
      Icon: Elevator
    },
    {
      title: translate("listingsPage.tableColumns.hasGarden"),
      value: boolToYesNo(hasGarden)
    },
    {
      title: translate("listingsPage.tableColumns.hasParking"),
      value: boolToYesNo(hasParking)
    },
    {
      title: translate("listingsPage.tableColumns.hasStorage"),
      value: boolToYesNo(hasStorage)
    },
    {
      title: translate("listingsPage.tableColumns.hasTerrace"),
      value: boolToYesNo(hasTerrace)
    },
    {
      title: translate("listingsPage.tableColumns.hasBars"),
      value: boolToYesNo(hasBars)
    },
    {
      title: translate("listingsPage.tableColumns.housingUnit"),
      value: boolToYesNo(housingUnit)
    },
    {
      title: translate("listingsPage.tableColumns.protectedSpace"),
      value: boolToYesNo(protectedSpace)
    },
    {
      title: translate("listingsPage.tableColumns.hasAirConditioning"),
      value: boolToYesNo(hasAirConditioning)
    },
    {
      title: translate("listingsPage.tableColumns.accessibleForDisabled"),
      value: boolToYesNo(accessibleForDisabled)
    },
    {
      title: translate("listingsPage.tableColumns.evictionDate"),
      value: timestampToDate(evictionDate)
    },
    ...(withOptionalProps
      ? [
          {
            title: translate("listingsPage.tableColumns.exclusivity"),
            value: timestampToDate(exclusivity)
          },
          {
            title: translate("listingsPage.tableColumns.fee"),
            value: fee
              ? `${fee}%${
                  feeIncludesTaxes
                    ? ` (${translate("listingsPage.tableColumns.feeIncludesTaxes")})`
                    : ""
                }`
              : "-"
          },
          {
            title: translate("listingsPage.tableColumns.addressAreaNickname"),
            value: addressAreaNickname ? addressAreaNickname : "-"
          },
          {
            title: translate("listingsPage.tableColumns.cleaningFee"),
            value: numberToCurrency(cleaningFee)
          },
          {
            title: translate("listingsPage.tableColumns.municipalityTaxes"),
            value: numberToCurrency(municipalityTaxes)
          },
          {
            title: translate("listingsPage.tableColumns.listingType"),
            value: listingType ? translate(`listingsPage.listingTypeLabel.${listingType}`) : "-"
          },
          {
            title: translate("listingsPage.tableColumns.listingState"),
            value: listingState ? translate(`listingsPage.listingStateLabel.${listingState}`) : "-"
          },
          {
            title: translate("listingsPage.tableColumns.projectName"),
            value: projectName ? projectName : "-"
          },
          {
            title: translate("listingsPage.tableColumns.sizeOfGardenM2"),
            value: `${sizeOfGardenM2 ?? "-"} m²`
          },
          {
            title: translate("listingsPage.tableColumns.sizeOfTerraceM2"),
            value: `${sizeOfTerraceM2 ?? "-"} m²`
          }
        ]
      : [])
  ];
}

export function boolToYesNo(value: boolean): string {
  const { t: translate } = useTranslation();

  return value ? translate("general.yes") : "-";
}

export function numberToCurrency(value: number | null): string {
  return value ? `₪${fNumber(value)}` : "-";
}

export function removeNumberFromAddress(address: string): string {
  return address?.replace(/\d+/g, "").trim();
}

export function getNumberFromAddress(address: string): number | null {
  const num = address?.replace(/\D/g, "");
  const parsed = parseInt(num);
  return isNaN(parsed) ? null : parsed;
}

export function timestampToDate(timestamp: firebase.firestore.Timestamp | null): string {
  const { themeDirection } = useSettings();

  if (!timestamp) return "-";

  const isRTL = themeDirection === "rtl";

  return fDate(timestamp.toDate(), isRTL);
}
